export const getHttpError = (error: any, checkMessageType?: number) => {
  if (error?.response?.status == null) {
    if (
      error?.response?.status == null ||
      error?.response?.status == undefined
    ) {
      // Handle ERR_NETWORK case or when there is a CORS error
      if (error.message === 'Network Error') {
        return checkMessageType ? 'ERRORS.MA_IR_016' : 'ERRORS.MR_IR_004'
      }
      return undefined
    }
  }
  switch (error.response.status) {
    case 400:
      return checkMessageType ? 'ERRORS.MA_IR_016' : 'ERRORS.MR_IR_004'
    case 401:
      return checkMessageType ? 'ERRORS.MA_IR_016' : 'ERRORS.MR_IR_005'
    case 404:
      return checkMessageType ? 'ERRORS.MA_IR_016' : 'ERRORS.MR_IR_006'
    case 409:
      return checkMessageType ? 'ERRORS.MA_IR_016' : 'ERRORS.MR_IR_007'
    case 500:
      return checkMessageType ? 'ERRORS.MA_IR_016' : 'ERRORS.MR_IR_004'
    default:
      return checkMessageType ? 'ERRORS.MA_IR_016' : 'ERRORS.MR_IR_004'
  }
}
