import i18next from 'i18next'
import _every from 'lodash/every'
import _find from 'lodash/find'
import _some from 'lodash/some'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { roleNames, routesConstant } from '../appConstants'
import { useAppContext } from '../context/login/hooks/AppContextProvider'
import { localStorageUtils } from '../utils/localStorageUtils'
import { catchHttpError } from '../utils/getHttpCatchError'
import { useAuth } from '../context/login/components/organisms/Protected/UserLogin'
import { UserInfoTenants } from '../data/types/UserInfo'
import { useNavigation } from 'react-navi'
import {
  getResponseMessage,
  getErrorMessage,
} from '../utils/handleHttpResponseUtils'
import { AuthService } from '../services/AuthService'
import { useMutation } from '@tanstack/react-query'
import { UserPreferenceParam } from '../data/types/UserSetting'

/**
 * useHeaderState
 * Display Logo, Userid, langugage, user-preference and logout
 * OnClick of language and user preference dropdown and allow user to update
 *
 * @returns
 */

export const useHeaderState = () => {
  const { t } = useTranslation()
  const {
    activeTenantId,
    currentTenantId,
    tenantsList,
    activeTenantData,
    userInfoData,
  } = useAuth()

  const { error } = useAppContext() ?? {}
  localStorageUtils.setUserLanguage('ja')
  const [isVisibleLanguage, setIsVisibleLanguage] = useState<boolean>(false)
  const getUserLanguage: string | '' = localStorageUtils.getUserLanguage()
  const [userLanguage, setUserLanguage] = useState<string>(getUserLanguage)
  const tenantListOptions = tenantsList ?? []
  const userTenantDisplayName = activeTenantData?.label || ''
  const userName = userInfoData?.user_attribute?.user_name || ''
  const userEmail = userInfoData?.email || ''

  // toggle language dropdown
  const toggleLanguageDropdown = () => {
    setIsVisibleLanguage(!isVisibleLanguage)
  }

  //change language on selection language from dropdown
  const changeLanguage = (lang: string) => {
    i18next.changeLanguage(lang)
    localStorageUtils.setUserLanguage(lang)
  }

  // Mutation function to update userpreference data on selecting tenant from header
  const handleTenantSwitch = useMutation({
    mutationFn: (body: UserPreferenceParam) =>
      AuthService.putUserPreference(body),
    onSuccess(data) {
      const message = getResponseMessage(data)
      if (message) {
        error.setMessage(message)
        return null
      }
      if (data.status === 200) {
        window.location.href = '/'
      }
      return null
    },
    onError(err) {
      const message = getErrorMessage(err)
      if (message) {
        error.setMessage(t(message))
      }
    },
  })

  React.useEffect(() => {
    if (userLanguage) {
      changeLanguage(userLanguage)
    }
  }, [userLanguage])

  return {
    toggleLanguageDropdown,
    changeLanguage,
    userTenantDisplayName,
    tenantListOptions,
    activeTenantId,
    currentTenantId,
    userName,
    userEmail,
    handleTenantSwitch,
  }
}
