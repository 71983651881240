import { createContext, useCallback, useContext, useState } from 'react'
import { useErrorState } from '../../../../hooks/useErrorState'
import { useHasUserSettingState } from '../../../../hooks/useHasUserSettingState'
import { useIsLoggedInState } from '../../../../hooks/useIsLoggedInState'
import { AlertMessage, AppContextData } from './types'
import { alertType } from '../../../../appConstants'

export const AppContext = createContext<AppContextData | undefined>(
  {} as AppContextData
)

export const useAppContext = (): AppContextData => {
  const appContext = useContext(AppContext)
  if (!appContext) {
    throw new Error('appContext must be used within the AppContext.Provider')
  }
  return appContext
}

export const useAppContextValue = (): AppContextData => {
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [role, setRole] = useState(0)
  const [alertMessage, setAlertMessage] = useState<AlertMessage>({
    type: alertType.DANGER,
    message: '',
  })

  const setMessage = useCallback((message: string) => {
    console.warn('deprecated set error message being used, API errors will be automatically handled, use setAlertMessage to show alerts')
    setErrorMessage(message)
  }, [])

  return {
    error: {
      errorMessage,
      setMessage,
    },
    errorMessage,
    errorMessageState: useErrorState(),
    isLoggedInState: useIsLoggedInState(),
    hasUserSettingState: useHasUserSettingState(),
    user: {
      role,
      setRole,
    },
    alert: {
      alertMessage,
      setAlertMessage,
    },
  }
}
