function escapeCookieValue(value: string): string {
  return value.replace(/[\s"';%&<>=]/g, encodeURIComponent); // escape unsafe characters
}

// returns a cookie (if found) or empty string if not found
export function getCookie(cookieName: string): string {
  const cookieValue =
    ('; ' + document.cookie).split(`; ${cookieName}=`).pop()?.split(';')[0] ??
    ''

  return escapeCookieValue(cookieValue)
}

// delete a cookie
export function removeCookie(name: string) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
}

// set a cookie
export function setCookie(name: string, value: string) {
  const sanitizedValue = escapeCookieValue(value)
  document.cookie = name + '=' + sanitizedValue + '; Path=/;'
}
