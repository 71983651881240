import { localStorageUtils } from '../../utils/localStorageUtils'
import { userConstants } from '../../appConstants'
import { useAppContext } from '../../context/login/hooks/AppContextProvider'
import { removeCookie } from '../../utils/cookie'
/**
 * LogoutState type
 */
type LogoutState = {
  handleLogout: (val?: any) => void
}

/**
 * useLogoutState is functional component for Logout button/event
 * It performs actions like :
 *  - Logout  login
 *  - Clear localStorage
 *
 * @returns LogoutState
 *
 */
export const useLogoutState = (): LogoutState => {
  const { isLoggedInState, hasUserSettingState } = useAppContext()

  const handleLogout = (navigation: any) => {
    try {
      handlePostLogout()
      window.location.href = '/user-login'
      // navigation.navigate('/user-login')
    } catch (e) {
      console.error(e)
    }
  }

  //hook to handle Post logout process to clear storage values and navigate user to login page
  const handlePostLogout = () => {
    localStorage.removeItem('SaaSusIdToken')
    removeCookie('SaaSusRefreshToken')
    removeCookie('idToken')
    localStorage.removeItem(userConstants.userInfo)
    localStorage.removeItem(userConstants.isLoggedIn)
    localStorage.removeItem(userConstants.accessToken)
    localStorage.removeItem(userConstants.userSetting)
    localStorage.removeItem(userConstants.userPreferenceMasterData)
    localStorage.removeItem(userConstants.registered_powerplant)
    hasUserSettingState.setHasUserSettingFlag(false)
    isLoggedInState.setIsLoggedInFlag(false)
    localStorageUtils.removeAll()
  }
  return {
    handleLogout,
  }
}
