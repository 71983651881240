import {
  FETCH_HAZARD_AREA_AFTER_MINUTE,
  FETCH_HAZARD_AREA_AFTER_MINUTES,
} from '../../../appConstants'
import { DisasterService } from '../../../services/disasterRiskManagement/DisasterRiskManagementService'

export function reFetchHazardAreaInterval(
  response:
    | Awaited<ReturnType<typeof DisasterService.fetchDisasterArea>>
    | undefined
) {
  // if hazard area present, refetch after 30 mins
  // if not, refetch after every 1 minute
  const hazardAreaGeometryLength = response?.data?.hazard_area?.geometry?.length
  const hasHazardArea = hazardAreaGeometryLength && hazardAreaGeometryLength > 0
  const msInMin = 60 * 1000
  return hasHazardArea
    ? FETCH_HAZARD_AREA_AFTER_MINUTES * msInMin
    : FETCH_HAZARD_AREA_AFTER_MINUTE * msInMin
}
