export const TRANSLATIONS_JA = {
  PRODUCT_NAME: '災害対応',
  TITLES: {
    REGISTRATION: '新ユーザー登録',
    SELECT: '選択…',
    USER_ID: 'ユーザーID',
    NAME: '氏名',
    ALL: ' 全選択',
    DATA_NOT_FOUND: 'データが存在しません。',
    USER_DATA_NOT_FOUND: 'ユーザーの安全登録記録はありません。',
    DISASTER_INFORMATION_MANAGEMENT: '災害情報管理',
    DISASTER_CONFIRMATION_TITLES: '警報を発信し', // '災害発生登録',
    DISASTER_ACTUAL_CONFIRMATION_CONTENT: '状態を災害モードに変更しますか？',
    // '発電所構内にいる方へ災害に関するプッシュ通知が<br />送信され、位置情報の取得を開始します。<br />よろしいでしょうか？',
    DISASTER_PRACTICE_CONFIRMATION_TITLES: '【訓練】警報を発信し', // '【訓練】災害発生登録',
    DISASTER_PRACTICE_CONFIRMATION_CONTENT: '状態を災害モードに変更しますか？',
    // '発電所構内にいる方へ災害に関するプッシュ通知が<br />送信され、位置情報の取得を開始します。<br />よろしいでしょうか？',
    DISASTER_CONFIRMATION_COMPLETE: '災害発生場所を登録してください',
    DISASTER_CANCELLATION_TITLES: '災害発生ステータスの変更',
    DISASTER_CANCELLATION_CONTENT: '災害モードを解除しますか？',
    DISASTER_CANCELLATION_COMPLETE: '災害対応お疲れ様でした。',
    DISASTER_LOCATION_CONFIRMATION_TITLES: '災害発生場所を設定し', // '災害発生場所の登録',
    DISASTER_ACTUAL_LOCATION_CONFIRMATION_CONTENT:
      '避難誘導サポートを開始しますか？',
    // '災害発生場所を設定し<br />避難誘導サポートを開始しますか？',
    DISASTER_PRACTICE_LOCATION_CONFIRMATION_TITLES:
      '【訓練】災害発生場所を設定し', // '【訓練】災害発生場所の登録',
    DISASTER_PRACTICE_LOCATION_CONFIRMATION_CONTENT:
      '避難誘導サポートを開始しますか？',
    // '災害発生場所を設定し<br />避難誘導サポートを開始しますか？',
    LOCATION_INFORMATION: '位置情報',
    DISASTER_LOCATION_REGISTERATION_ALERT: '災害発生場所を登録しました。',
    DISASTER_LOCATION_UPDATING_ALERT: '災害発生場所を変更しました。',
    DISASTER_LOCATION_UPDATE_CONFIRMATION_TITLE: '災害発生場所を変更し',
    DISASTER_PRACTICE_LOCATION_UPDATE_CONFIRMATION_TITLE:
      '【訓練】災害発生場所を変更し',
    DISASTER_LOCATION_UPDATE_CONFIRMATION: '避難誘導サポートを再開しますか？',
    USER_NAME: '氏名',
    AFFILIATION: '所属',
    STATUS: '安否状況',
    EVACUATION_STATUS: '避難状況',
    SAFETY_REGISTRATION_STATUS: '安否登録状況',
    NO_SEARCH_DATA_FOUND: 'おっと！データが見つかりませんでした。',
    SEARCH_TRY_AGAIN: '検索条件を確認し、やり直してください。',
    LOCATION_FETCHING_ALERT: 'ユーザー位置情報の取得中です',
    MAP_DATA_NOT_FOUND_MESSAGE: 'データの取得に失敗しました。',
    EXTINGUISHING_OUTDOOR_HYDRANT: '消火用屋外給水栓（7.5）',
    OUTDOOR_FIRE_HYDRANT_65: '屋外消火栓（6.5）',
    OUTDOOR_FIRE_HYDRANT_50: '屋外消火栓（5.0）',
    AUXILIARY_FOAM_FIRE_STOPPER_65: '補助泡消火栓（6.5）',
    AED: 'AED',
    SANDBAG: '土嚢',
    DISASTER_MATERIALS_EQUIPMENT: '災害用資機材',
    PROTECTIVE_EQUIPMENT: '防護具',
    HANDSET: 'ハンドセット',
    LOCAL_COMMAND_HQ_VEHICLE: '現地指揮本部車',
    EMERGENCY_SHOWER: '緊急用シャワー',
    ICONS_REGISTRATION_SETTING: 'アイコン登録設定',
    SHOW_HIDE: '表示・非表示',
    DEFAULT_DISASTER_LOCATION: '災害発生場所',
    REGISTERATION_ITEMS_SUCCESS: 'を登録しました。',
    REGISTERATION_ITEMS_FAILURE: 'を登録失敗しました。',
    ZOOM_LEVEL_ALERT: 'を登録するには地図を拡大してください。',
    TURN_ON_TOGGLE:
      'アイコンを登録するには、設備アイコンをオンにしてください。',
    PREVENTION_ITEMS_DISPLAY_ZOOM_LEVEL_ALERT:
      'アイコンを表示するには地図を拡大する必要があります。',
    PREVENTION_ITEMS_DELETION_TITLES: 'の削除確認',
    PREVENTION_ITEMS_DELETION_CONTENT: 'の削除を実行しますか？',
    POWER_PLANT_BASE_PREVENTION_ITEMS_DELETION_SUCCESS: 'を削除しました。',
    POWER_PLANT_BASE_PREVENTION_ITEMS_DELETION_FAILURE: 'を削除失敗しました。',
    PREVENTION_ITEM_HIDE_SHOW_PANEL_ENABLE_DISABLE_ALERT:
      '登録設定で選択されている項目',
    NORMAL_MODE: '通常モード',
    DISASTER_MODE: '災害モード',
    DISASTER_LOCATION: '災害場所設定',
    EVACUATION_GUIDANCE_SUPPORT: '避難誘導サポート中',
    DOUBLE_CLICK_TO_REGISTER:
      '災害発生場所をダブルクリックして設定してください。',
    DISASTER_END: '災害対応お疲れ様でした。',
    SHOW_HIDE_FACILITY_ICONS: '設備アイコン表示 / 非表示',
    REGISTER_ITEMS_SETTINGS: '設備アイコン登録設定',
    COMPANY: '会社',
    TABLE_STATUS: 'ステータス',
    NOT_FOUND: 'おっと！データが見つかりません。',
    CHANGE_KEYWORD: '検索キーワードを変更またはこのキーワードから新規追加',
  },
  LABELS: {
    POWER_PLANT: '発電所',
    UNIT: 'ユニット',
    LANGUAGE: '言語',
    ERROR: 'エラー',
    LANGUAGE_SETTING: '言語設定',
    JAPANESE: '日本語',
    ENGLISH: '英語',
    FEEDBACK: 'フィードバック',
    SERIOUS_INJURY: '重傷',
    MINOR_INJURY: '軽傷',
    SAFE: '安全',
    NOT_ANSWERED: '未回答',
    SELECT_ALL: '全て',
    OTHER: 'その他',
    PEOPLE: '名',
    EVACUATION_COMPLETED: '避難完了',
    NO_MOVEMENT: '移動なし',
    MOVING: '移動中',
    UNAVAILABLE: '取得不可',
    TOTAL_NUMBER: '総数',
    PERSON: '人',
    TRAIN: '訓練',
    SELECT_DISASTER: '災害を選択',
    AMMONIA_LEAKS: 'ガス漏洩',
    FIRE: '火災',
    DISASTER_TYPE_TITLE_TEXT: '災害種別',
    DISASTER_TYPE_DEFAULT_TEXT_SELECTION: '災害種類を選択',
    STATUS_BLANK: 'ー',
    COMPANY_SELECTION: '会社選択',
    EVACUATION_STATUS_SELECTION: '避難状況選択',
    VISITORS: '入構者',
    LIST: 'リスト',
    EQUIPMENT_ICON: '設備アイコン',
    QR_PAGE_MENU_ITEM: '入構者用QRコード発行',
    TRAINS: '訓練',
    POWER_PLANT_USER: '発電所ユーザー',
    GUEST_USER: 'ゲストユーザー',
  },
  BUTTONS: {
    CANCEL: 'キャンセル',
    REGISTER: '登録',
    LOGOUT: 'ログアウト',
    FEEDBACK: 'フィードバック',
    SUBMIT: '設定',
    CONFIRM_NO: 'いいえ',
    CONFIRM_YES: 'はい',
    DISASTER: '災害発生',
    CANCELLATION: '災害モード解除',
    CLEAR: 'クリア',
    APPLY: '完了',
    CLOSE: '閉める',
  },
  ERRORS: {
    INVALID_USERSETTING:
      '無効なユーザー設定が見つかりました。管理者にご連絡ください。',
    MR_IR_004:
      '想定しないエラーが発生しました。システム管理者に問い合わせてください。',
    MR_IR_005: 'セッションの有効期限が切れました。再度ログインしてください。',
    MR_IR_006: 'データの取得に失敗しました。',
    MR_IR_007:
      '他のユーザーが既に更新しています。入力内容を保存することができませんでした。',
    MA_IR_011:
      'ユーザー設定が保存できませんでした。ログアウト後に再試行してください。',
    MA_IR_012:
      'ユーザー情報が存在しないため、ユーザー設定変更に失敗しました。システム管理者に問い合わせてください。',

    MR_IR_013:
      'ユーザー登録がされていません。ユーザー登録の手続きを行ってください。',
    MA_IR_016: 'タスク管理（ToT）との連携に失敗しました。',
  },
}
