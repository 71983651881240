import React from 'react'
import { createPortal } from 'react-dom'
import { Trans } from 'react-i18next'

export function DisasterEndModal() {
  return createPortal(
    <div className="modal-area without-overlay-bg">
      <div className="modal-dialog uk-text-center color-white bg-grey-95 min-h-530 uk-flex uk-flex-middle uk-flex-center">
        <h2 className="modal-title-3 uk-margin-medium-top uk-margin-medium-bottom">
          <Trans>TITLES.DISASTER_END</Trans>
        </h2>
      </div>
    </div>,
    document.body
  )
}
