//Components
import React from 'react'
import { mount, route, map, compose } from 'navi'
import LoginPage from '../components/auth/Login'
import TermsAndConditions from '../pages/termsAndConditions'
import ApplicationQrCode from '../pages/applicationQrCode'
import ApkDownload from '../pages/apkDownload'
import NewDashboard from '../pages/newDashboard'
const routes = compose(
  mount({
    '/user-login': map(async () => {
      return route({ view: <LoginPage /> })
    }),
    '/terms-and-conditions': map(async () => {
      return route({ view: <TermsAndConditions /> })
    }),
    '/application-qr-code': map(async () => {
      return route({ view: <ApplicationQrCode /> })
    }),
    '/download-apk': map(async () => {
      return route({ view: <ApkDownload /> })
    }),
    '/': map(async () => {
      return route({ view: <NewDashboard /> })
    }),
  })
)

export { routes }
