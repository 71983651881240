import React, { Suspense } from 'react'
import { routesConstant } from './appConstants'
import {
  AppContext,
  useAppContextValue,
} from './context/login/hooks/AppContextProvider'
import { routes } from './routes'
import { NotFoundBoundary, Router } from 'react-navi'
import { View } from 'react-navi'
import ProtectedRoute from './context/login/components/organisms/Protected/Protected'
import { AuthProvider } from './context/login/components/organisms/Protected/UserLogin'
import { ReactQueryProvider } from './context/login/components/organisms/ReactQueryProvider'
import { ToasterProvider } from './context/login/components/organisms/ToasterProvider'
const redirectHomePage = () => {
  window.location.href = routesConstant.LANDING_PAGE
}

const App: React.FC = () => {
  const appContextValue = useAppContextValue()

  return (
    <Router routes={routes}>
      <AppContext.Provider value={appContextValue}>
        <ReactQueryProvider>
          <ToasterProvider>
            <AuthProvider>
              <ProtectedRoute>
                <Suspense fallback={null}>
                  <NotFoundBoundary render={async () => redirectHomePage()}>
                    <View />
                  </NotFoundBoundary>
                </Suspense>
              </ProtectedRoute>
            </AuthProvider>
          </ToasterProvider>
        </ReactQueryProvider>
      </AppContext.Provider>
    </Router>
  )
}
export { App }
