import isArray from "lodash/isArray"
import { USER_EVACUATION_STATUSES, USER_SAFETY_STATUSES } from "../../appConstants"
import { ALL, EvacuationHistoryResponse, FilterState, OTHER, UserEvacuationStatus, UserSafetyStatus } from "../../data/types/DisasterRiskManagement"
import { DisasterService } from "../../services/disasterRiskManagement/DisasterRiskManagementService"

export function filterUserSafetyList({
  filterState,
  safetyListData, // user safety - minor injury, serious injury, etc
  evacuationHistoryRes,
}: {
  filterState: FilterState
  safetyListData:
    | Awaited<ReturnType<typeof DisasterService.fetchUserSafetyList>>['data']
    | undefined
  evacuationHistoryRes: EvacuationHistoryResponse | undefined
}) {
  const evacuationHistoryData = evacuationHistoryRes?.evacuation_history || []
  const evacuationHistoryFlag = !!evacuationHistoryRes?.display_evacuation_status

  // The goal is to show safety list
  // filtered by partner companies
  // and filtered by evacuation history's evacuation statuses
  const selectedPartnerCompanies = filterState.partnerListSelection
    .filter((partner) => partner.selected)
    .map((partner) => partner.label)

  if (!safetyListData) {
    return {
      filteredByPartnersEvacuationStatusAndSafetyStatus: [],
      filteredByPartnersAndEvacuationStatus: [],
      filteredByPartners: [],
    }
  }

  const allPartnerCompaniesSelected = filterState.partnerListSelection.some(
    (partner) => partner.label === ALL && partner.selected
  )
  const otherPartnerCompanySelected = filterState.partnerListSelection.some(
    (partner) => partner.label === OTHER && partner.selected
  )

  // filter safety list data by partner companies
  const addFilteredByPartners = safetyListData.filter((listItem) => {
    const partnerCompanyName = listItem.company as any

    // if every partner company selected
    // just return everything

    if (allPartnerCompaniesSelected) {
      return true
    }

    if (otherPartnerCompanySelected) {
      // if a company found that is not in filter
      const allPartnerCompaniesInDropdown = filterState.partnerListSelection.map(
        (partner) => partner.label
      )
      return !allPartnerCompaniesInDropdown.includes(partnerCompanyName)
    }

    return (
      partnerCompanyName &&
      selectedPartnerCompanies.includes(partnerCompanyName)
    )
  })

  // filter safety list data by evacuation statuses (moving, evacuated, etc)
  const selectedEvacuationStatuses = filterState.evacuationListSelection
    .filter((item) => item.selected)
    .map((item) => item.label)

  const everyEvacuationOptionSelected = filterState.evacuationListSelection.every(
    (item) => item.selected
  )

  // users filtered by evacuation data
  const evacuationHistoryUserIdsBySelectedEvacuationStatuses =
    evacuationHistoryData
      ?.filter((item) => {
        // if every evacuation status is selected
        // just return all evacuation history data
        if (everyEvacuationOptionSelected) {
          return true
        }

        const evacuationStatus = item.evacuation_status as UserEvacuationStatus

        return selectedEvacuationStatuses.includes(evacuationStatus)
      })
      .map((item) => item.user_id) ?? []

  // User table displays default evacuation status if not found
  // This is done to show respective items for only that status
  const defaultEvacuationStatus = getDefaultEvacuationStatus({
    evacuationFlag: evacuationHistoryFlag,
    evacuationHistoryData: evacuationHistoryData,
  })

  // further filter user safety list data by evacuation status
  // (pick only those users which came filtered by evacuation status - moving, evacuation completed, etc )
  const addFilteredByEvacuationStatus = addFilteredByPartners.filter((item) => {
    // if every evacuation status is selected
    // just return all safety list data filtered by partner companies
    if (everyEvacuationOptionSelected) {
      return true
    }

    const foundMatchingEvacuationStatus = evacuationHistoryUserIdsBySelectedEvacuationStatuses?.includes(
      item.user_id
    )

    const matchingEvacuationStatus = evacuationHistoryData?.find(
      (evacData) =>
        evacData.user_id?.toLowerCase() === item?.user_id?.toLowerCase()
    )

    const includeAsDefault = selectedEvacuationStatuses.includes(
      defaultEvacuationStatus
    )

    // return foundMatchingEvacuationStatus
    return matchingEvacuationStatus
      ? foundMatchingEvacuationStatus
      : includeAsDefault
  })

  // further pick up by user safety status (minor injured, sever injured, etc)
  const selectedSafetyStatuses = filterState.safetyListSelection
    .filter((item) => item.selected)
    .map((item) => item.label)

  // In current implementation, if none of safety checkboxes are selected
  // that means return all (return everything regardless of their safety status)
  const everySafetyStatusSelected = selectedSafetyStatuses.length === 0

  const addFilteredBySafetyStatus = addFilteredByEvacuationStatus.filter(
    (item) => {
      if (everySafetyStatusSelected) {
        return true
      }

      const safetyStatus = item.user_status // user_status is user safety status
      return selectedSafetyStatuses.includes(
        getDefaultUserStatus(safetyStatus as UserSafetyStatus)
      )
    }
  )

  return {
    filteredByPartnersEvacuationStatusAndSafetyStatus: addFilteredBySafetyStatus,
    filteredByPartnersAndEvacuationStatus: addFilteredByEvacuationStatus,
    filteredByPartners: addFilteredByPartners,
  }
}

// get the default user status if API returns status as null
export function getDefaultUserStatus(status: UserSafetyStatus | null) {
  return status ?? USER_SAFETY_STATUSES.NOT_ANSWERED
}

export function getDefaultEvacuationStatus({
  evacuationFlag,
  evacuationHistoryData,
}: {
  evacuationFlag: boolean
  evacuationHistoryData: EvacuationHistoryResponse['evacuation_history']
}) {
  return evacuationFlag || isArray(evacuationHistoryData)
    ? USER_EVACUATION_STATUSES.UNAVAILABLE
    : USER_EVACUATION_STATUSES.STATUS_BLANK
}