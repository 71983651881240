import React from 'react'

export const useHasUserSettingState = (): {
  hasUserSetting: boolean
  setHasUserSettingFlag: (flag: boolean) => void
  getHasUserSetting: () => boolean
} => {
  const [hasUserSetting, setHasUserSetting] = React.useState<boolean>(false)
  const setHasUserSettingFlag = (flag: boolean) => {
    setHasUserSetting(flag)
  }
  const getHasUserSetting = () => {
    return hasUserSetting
  }
  return {
    hasUserSetting,
    setHasUserSettingFlag,
    getHasUserSetting,
  }
}
