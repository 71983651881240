import React, { useEffect, useReducer } from 'react'

import iconVolumeLogo from '../../assets/images/icn-volume.svg'
import iconArrowDownGreyLogo from '../../assets/images/icn-arrow-down-grey.svg'
import { useAuth } from '../../context/login/components/organisms/Protected/UserLogin'
import { roleNames } from '../../appConstants'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  DisasterService,
  PostDisasterStatusParam,
  PutDisasterPlaceParam,
  PutDisasterStatusParam,
} from '../../services/disasterRiskManagement/DisasterRiskManagementService'
import ConfirmBoxModal from '../../components/common/ConfirmBoxModal'
import { RegisterToDoubleClickToast } from './RegistertoDoubleClickToast'
import { reFetchHazardAreaInterval } from './common/refetchHazardArea'
import { DisasterEndModal } from './DisasterEndModal'
import { useAppContext } from '../../context/login/hooks/AppContextProvider'
import { useIsCorrectMapCoordinatesResponse } from '../../hooks/queries/useMapCoordinates'

type State = {
  selectedDisasterType: 'AMMONIA_LEAKS' | 'FIRE' | null
  disasterRegistrationModal: boolean
  disasterCancellationModal: boolean
  trainingCheckbox: boolean
  afterDisasterCancelModal: boolean
}

const DisasterRegistrationControlPanel = () => {
  const { role: RoleData, currentTenantId } = useAuth()
  const isAdmin = RoleData === roleNames.app_admin

  const { alert } = useAppContext()
  const { setAlertMessage } = alert

  const mapCoordinatesSuccess = useIsCorrectMapCoordinatesResponse()

  const {
    data: latestDisasterStatus,
    isLoading: latestDisasterStatusLoading,
    error: latestDisasterStatusError,
  } = useQuery({
    queryKey: ['disaster-status', currentTenantId],
    enabled: !!currentTenantId && mapCoordinatesSuccess,
    queryFn: () => DisasterService.fetchDisasterStatus(currentTenantId),
  })

  const disasterId = latestDisasterStatus?.data.status
    ? latestDisasterStatus?.data.disaster_id
    : undefined
  const practiceFlag = latestDisasterStatus?.data.practice_flag
  const hasDisaster = latestDisasterStatus?.data.status
  const disasterType = latestDisasterStatus?.data.disaster_type ?? null

  // update disaster type select based on disaster type from API
  // or if no disaster, revert to initial value
  useEffect(() => {
    // only if disaster is active
    if (hasDisaster) {
      updateState({
        selectedDisasterType: disasterType as any,
      })
    }

    // if no disaster revert back selected disaster type
    // and training checkbox
    if (!hasDisaster) {
      updateState({
        selectedDisasterType: null,
        trainingCheckbox: false,
      })
    }
  }, [disasterType, hasDisaster])

  const isEnableTrainingCheckbox = !hasDisaster && isAdmin

  const { t } = useTranslation()
  const [idx, reRenderDisasterSelectMenu] = useReducer((i) => i + 1, 0) // for force re-rendering sandwichMenu

  const additionalClassName = isEnableTrainingCheckbox
    ? ''
    : 'cursor-not-allowed'

  const disasterTypes = {
    FIRE: t('LABELS.FIRE'),
    AMMONIA_LEAKS: t('LABELS.AMMONIA_LEAKS'),
  }
  const options = Object.entries(disasterTypes).map(([key, value]) => ({
    value: key,
    label: value,
  }))

  const [state, updateState] = useReducer(
    function (prev: State, next: Partial<State>) {
      return { ...prev, ...next }
    },
    {
      selectedDisasterType: null,
      disasterRegistrationModal: false,
      disasterCancellationModal: false,
      trainingCheckbox: false,
      afterDisasterCancelModal: false,
    }
  )

  const queryClient = useQueryClient()

  const registerDisaster = useMutation({
    mutationFn: (body: PostDisasterStatusParam) =>
      DisasterService.postDisasterStatus(body, currentTenantId),
    onSuccess(data, variables, context) {
      // refetch latest disaster status
      queryClient.invalidateQueries({
        queryKey: ['disaster-status', currentTenantId],
      })
    },
  })

  const {
    data: disasterAreaResponse,
    isLoading: disasterAreaLoading,
  } = useQuery({
    queryKey: ['disaster-area', currentTenantId, disasterId],
    enabled: !!currentTenantId && !!disasterId,
    queryFn: () =>
      DisasterService.fetchDisasterArea(currentTenantId, disasterId as number),
    refetchInterval: reFetchHazardAreaInterval,
  })

  const hasMarker =
    !!disasterAreaResponse?.data.status &&
    !!disasterAreaResponse?.data.hazard_point.geometry

  const disasterAreaMutation = useMutation({
    mutationFn: (
      body: PutDisasterPlaceParam & {
        tenantId: string
        disasterId: number
        status: boolean
      }
    ) =>
      DisasterService.putDisasterPlace(body.tenantId, body.disasterId, {
        latitude: body.latitude,
        longitude: body.longitude,
        status: body.status,
      }),
    onSuccess(data, variables, context) {
      // refetch only if disaster is status is true
      if (hasDisaster) {
        queryClient.invalidateQueries({
          queryKey: ['disaster-status', currentTenantId],
        })
        // refetch disaster Area (to refresh data and redraw disaster marker)
        queryClient.invalidateQueries({
          queryKey: ['disaster-area', currentTenantId, disasterId],
        })
      }
    },
  })

  const cancelDisaster = useMutation({
    mutationFn: (body: PutDisasterStatusParam & { disasterId: number }) =>
      DisasterService.putDisasterStatus(
        { status: body.status },
        currentTenantId,
        body.disasterId
      ),
    onSuccess(data, variables, context) {
      // refetch latest disaster status
      queryClient.invalidateQueries({
        queryKey: ['disaster-status', currentTenantId],
      })

      if (!disasterId) {
        return
      }

      // show modal after cancelling disaster
      updateState({ afterDisasterCancelModal: true })
      // hide after 5 seconds
      const timeoutId = setTimeout(() => {
        updateState({ afterDisasterCancelModal: false })
        clearTimeout(timeoutId)
      }, 5000)

      // cancel disaster place if present
      if (hasMarker) {
        disasterAreaMutation.mutate({
          disasterId: disasterId,
          status: false,
          tenantId: currentTenantId,
        })
      }
    },
  })

  return (
    <div className="uk-flex uk-flex-middle uk-flex-between mt-30 bottom-item">
      {hasDisaster &&
      !hasMarker &&
      !disasterAreaLoading &&
      !latestDisasterStatusLoading ? (
        // if disaster registered but no marker (disaster point location) is present
        // Then present prompt to double click toast
        <RegisterToDoubleClickToast disasterType={disasterType as any} />
      ) : null}
      {state.disasterRegistrationModal ? (
        <ConfirmBoxModal
          data-id="disasterRegistrationModal"
          noAction={() => {
            // hide modal
            updateState({ disasterRegistrationModal: false })
          }}
          yesAction={() => {
            // call disaster register API
            const selectedDisasterType = state.selectedDisasterType
            if (!selectedDisasterType) {
              throw new Error('no disaster type selected')
            }
            updateState({ disasterRegistrationModal: false })

            registerDisaster.mutate({
              disaster_type: selectedDisasterType,
              practice_flag: state.trainingCheckbox,
            })
          }}
          messageTitle={
            state.trainingCheckbox
              ? t('TITLES.DISASTER_PRACTICE_CONFIRMATION_TITLES')
              : t('TITLES.DISASTER_CONFIRMATION_TITLES')
          }
          messageBody={
            state.trainingCheckbox
              ? t('TITLES.DISASTER_PRACTICE_CONFIRMATION_CONTENT')
              : t('TITLES.DISASTER_ACTUAL_CONFIRMATION_CONTENT')
          }
        />
      ) : null}
      {state.disasterCancellationModal ? (
        <ConfirmBoxModal
          noAction={() => {
            // hide modal
            updateState({ disasterCancellationModal: false })
          }}
          yesAction={() => {
            const disasterId = latestDisasterStatus?.data.status
              ? latestDisasterStatus?.data.disaster_id
              : undefined
            if (!disasterId) {
              throw new Error('disaster id not found!')
            }

            // hide cancel disaster confirm modal
            updateState({ disasterCancellationModal: false })

            // call disaster cancel API
            cancelDisaster.mutate({
              status: false,
              disasterId,
            })
          }}
          messageTitle={`${t('TITLES.DISASTER_CANCELLATION_CONTENT')}`}
          messageBody={''}
        />
      ) : null}
      {state.afterDisasterCancelModal ? <DisasterEndModal /> : null}
      <ul
        className={`uk-nav uk-nav-default uk-position-relative select-disaster ${additionalClassName} ${mapCoordinatesSuccess ? '' : 'cursor-not-allowed'}`}
      >
        <li className={additionalClassName}>
          <a
            className={`uk-flex-center ${additionalClassName} ${
              isEnableTrainingCheckbox ? '' : 'pointer-events-none'
            } ${mapCoordinatesSuccess ? '' : 'cursor-not-allowed pointer-events-none'}`}
            aria-expanded="false"
          >
            <span
              // white color when able to select dropdown
              className={`font-24 mr-10 ${
                isEnableTrainingCheckbox ? '' : 'color-grey-50'
              } ${mapCoordinatesSuccess ? '' : 'color-grey-50'}`}
            >
              {state.selectedDisasterType
                ? t(`LABELS.${state.selectedDisasterType}`)
                : t('LABELS.DISASTER_TYPE_DEFAULT_TEXT_SELECTION')}
            </span>
            <img
              src={iconArrowDownGreyLogo}
              className="uk-position-absolute icn-down-arrow"
              width="34"
              height="34"
              alt=""
            />
          </a>
          <div
            className="uk-light uk-arrow uk-arrow-bottom-right uk-position-absolute uk-width-1-1 uk-dropdown-comman custom-dropdown"
            data-uk-dropdown="pos:bottom-right; mode:click; offset:15;"
            style={{ left: '-10px', top: '63px' }}
            id="select-disaster-type-uk-dropdown"
            key={idx}
          >
            <ul className="uk-nav uk-navbar-dropdown-nav">
              {options.map((option, idx) => {
                return (
                  <li key={option.value}>
                    <label
                      className={`uk-width-1-1 uk-flex uk-flex-middle cursor-pointer ${
                        idx !== 0 ? 'mt-20' : ''
                      }`}
                    >
                      <span className="font-28">{option.label}</span>
                      <input
                        className="uk-radio uk-margin-auto-left"
                        type="radio"
                        value={option.value}
                        onChange={(e) => {
                          updateState({
                            selectedDisasterType: e.currentTarget.value as any,
                          })
                          reRenderDisasterSelectMenu()
                        }}
                        checked={state.selectedDisasterType === option.value}
                      />
                    </label>
                  </li>
                )
              })}
            </ul>
          </div>
        </li>
      </ul>
      <div className="uk-flex uk-flex-middle bottom-right">
        <label
          className={`mr-52 uk-flex uk-flex-middle column-gap-20 ${
            !isEnableTrainingCheckbox ? 'cursor-not-allowed' : ''
          }`}
        >
          <input
            className="uk-checkbox"
            type="checkbox"
            disabled={!isEnableTrainingCheckbox}
            checked={
              !!(
                state.trainingCheckbox ||
                // when disaster is present and
                // when practice flag comes from API, this should be checked
                (hasDisaster && practiceFlag && !isEnableTrainingCheckbox)
              )
            }
            onChange={(e) =>
              updateState({ trainingCheckbox: e.currentTarget.checked })
            }
          />
          <span
            className={`${
              !isEnableTrainingCheckbox
                ? 'cursor-not-allowed'
                : 'cursor-pointer'
            } font-24 color-white checkbox-label-text`}
          >
            <Trans>LABELS.TRAINS</Trans>
          </span>
        </label>
        {hasDisaster ? (
          <button
            className="uk-button uk-button-orange color-white uk-flex uk-flex-middle column-gap-20 btn-active-mode"
            type="button"
            disabled={!hasDisaster || !isAdmin}
            onClick={() => updateState({ disasterCancellationModal: true })}
            style={{ padding: '0 38px' }}
          >
            <span className="font-28">{t('BUTTONS.CANCELLATION')}</span>
          </button>
        ) : (
          <button
            className="uk-button uk-button-orange color-white uk-flex uk-flex-middle column-gap-20"
            type="button"
            disabled={!isEnableTrainingCheckbox || !state.selectedDisasterType || !mapCoordinatesSuccess}
            onClick={() => updateState({ disasterRegistrationModal: true })}
          >
            <img src={iconVolumeLogo} width="40" height="40" alt="HSSE-logo" />
            <span className="font-28">{t('BUTTONS.DISASTER')}</span>
          </button>
        )}
      </div>
    </div>
  )
}

export default DisasterRegistrationControlPanel
