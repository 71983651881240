import React from 'react'
import Spinner from './Spinner'

const NewSpinner = () => {
  return (
    <div className="overlay">
      <Spinner size={4} />
    </div>
  )
}

export default NewSpinner
