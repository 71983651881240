import isArray from 'lodash/isArray'
import React, { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'
import { Trans, useTranslation } from 'react-i18next'
import {
  DASH,
  FETCH_USER_EVACUATION_STATUS_AFTER_MINUTE,
  FETCH_USER_SAFETY_LIST_AFTER_MINUTES,
} from '../../appConstants'
import iconCloseLogo from '../../assets/images/icons/icn-close-table.svg'
import iconMaximizeLogo from '../../assets/images/icons/icn-maximize.svg'
import iconMinimizeLogo from '../../assets/images/icons/icn-minimize.svg'
import noDataAvailable from '../../assets/images/no-data-available.svg'
import { useAuth } from '../../context/login/components/organisms/Protected/UserLogin'
import {
  FilterState,
  UserSafetyListRes,
  UserSafetyStatus,
} from '../../data/types/DisasterRiskManagement'
import { DisasterService } from '../../services/disasterRiskManagement/DisasterRiskManagementService'
import {
  filterUserSafetyList,
  getDefaultEvacuationStatus,
  getDefaultUserStatus,
} from '../../utils/disasterRiskManagement/filterSafetyList'
import { getClassesForUserStatus } from '../../utils/commonUtils'
import { useIsCorrectMapCoordinatesResponse } from '../../hooks/queries/useMapCoordinates'

type UserSafetyListTableProps = {
  shouldSafetyListTableDisplay: boolean
  setShouldSafetyListTableDisplay: React.Dispatch<React.SetStateAction<boolean>>
  isTableMinimize: boolean
  setIsTableMinimize: React.Dispatch<React.SetStateAction<boolean>>
  // tableData: FetchUserSafetyListRes
  filterState: FilterState
  tableRef: React.RefObject<HTMLDivElement>
  setTableToDefaultPosition: () => void
}

const UserSafetyListTable: React.FC<UserSafetyListTableProps> = ({
  shouldSafetyListTableDisplay,
  setShouldSafetyListTableDisplay,
  isTableMinimize,
  setIsTableMinimize,
  filterState,
  tableRef,
  setTableToDefaultPosition,
}) => {
  const { t } = useTranslation()
  const { currentTenantId } = useAuth()

  const mapCoordinatesSuccess = useIsCorrectMapCoordinatesResponse()

  const { data: latestDisasterStatus } = useQuery({
    queryKey: ['disaster-status', currentTenantId],
    enabled: !!currentTenantId && mapCoordinatesSuccess,
    queryFn: () => DisasterService.fetchDisasterStatus(currentTenantId),
  })

  const disasterId = latestDisasterStatus?.data.status
    ? latestDisasterStatus?.data.disaster_id
    : undefined

  const { data: disasterAreaData } = useQuery({
    queryKey: ['disaster-area', currentTenantId, disasterId],
    enabled: !!currentTenantId && !!disasterId,
    queryFn: () =>
      DisasterService.fetchDisasterArea(currentTenantId, disasterId as number),
  })

  const disasterLocationRegistered = disasterAreaData?.data?.hazard_point
    ?.geometry
    ? disasterAreaData?.data?.hazard_point?.geometry
    : undefined

  const { data: evacuationApiData, error: evacuationApiError } = useQuery({
    queryKey: ['evacuation-history', disasterId],
    enabled: !!disasterId && !!disasterLocationRegistered,
    refetchInterval: FETCH_USER_EVACUATION_STATUS_AFTER_MINUTE * 60 * 1000,
    queryFn: () =>
      DisasterService.fetchEvacuationStatus(
        currentTenantId,
        Number(`${disasterId}`)
      ),
  })

  const { data: safetyListApiResponse } = useQuery({
    queryKey: ['user-safety-list', disasterId],
    enabled: !!disasterId,
    refetchInterval: FETCH_USER_SAFETY_LIST_AFTER_MINUTES * 60 * 1000,
    queryFn: () =>
      DisasterService.fetchUserSafetyList(Number(disasterId), currentTenantId),
  })

  const userSafetyListData = safetyListApiResponse?.data
  const evacuationHistoryData = evacuationApiData?.data?.evacuation_history
  const evacuationHistoryError = evacuationApiError

  const tableData: UserSafetyListRes[] = useMemo(() => {
    const evacuationHistory = isArray(evacuationHistoryData)
      ? evacuationHistoryData
      : []

    const displayEvacuationFlag = !!evacuationApiData?.data
      ?.display_evacuation_status

    return (
      filterUserSafetyList({
        evacuationHistoryRes: evacuationApiData?.data,
        filterState,
        safetyListData: safetyListApiResponse?.data,
      })?.filteredByPartnersEvacuationStatusAndSafetyStatus?.map(
        (userSafetyData) => {
          const matchingEvacuationStatus = evacuationHistory?.find(
            (evacData) =>
              evacData.user_id?.toLowerCase() ===
              userSafetyData?.user_id?.toLowerCase()
          )
          if (matchingEvacuationStatus) {
            return {
              ...userSafetyData,
              user_status: getDefaultUserStatus(
                userSafetyData.user_status as UserSafetyStatus
              ),
              evacuation_status: matchingEvacuationStatus.evacuation_status,
            }
          }

          const defaultEvacuationStatus = getDefaultEvacuationStatus({
            evacuationFlag: displayEvacuationFlag,
            evacuationHistoryData: evacuationHistory,
          })

          return {
            ...userSafetyData,
            user_status: getDefaultUserStatus(
              userSafetyData.user_status as UserSafetyStatus
            ),
            evacuation_status: defaultEvacuationStatus,
          }
        }
      ) ?? []
    )
  }, [
    evacuationApiData?.data,
    evacuationHistoryData,
    filterState,
    safetyListApiResponse?.data,
  ])

  return (
    <div
      ref={tableRef}
      className={
        'table-area' +
        (isTableMinimize ? ' table-minimize cursor-move' : '') +
        (shouldSafetyListTableDisplay ? ' table-show' : '')
      }
    >
      <div className="uk-flex uk-flex-bottom uk-flex-right uk-width-1-1 column-gap-15 uk-padding-small">
        <a
          id="minimize-btn"
          className={`icn-minimize ${
            isTableMinimize ? 'opacity-half pointer-events-none' : ''
          }`}
          onClick={() => setIsTableMinimize(true)}
        >
          <img
            // src="../../assets/images/icn-minimize.svg"
            src={iconMinimizeLogo}
            alt="icn-minimize"
          />
        </a>
        <a
          id="maximize-btn"
          className={`icn-maximize ${
            !isTableMinimize ? 'opacity-half pointer-events-none' : ''
          }`}
          onClick={() => {
            setIsTableMinimize(false)
            setTableToDefaultPosition()
          }}
        >
          <img
            // src="../../assets/images/icn-maximize.svg"
            src={iconMaximizeLogo}
            alt="icn-maximize"
          />
        </a>
        <a
          className="icn-close-table"
          onClick={() => setShouldSafetyListTableDisplay(false)}
        >
          <img
            // src="../../assets/images/icn-close-table.svg"
            src={iconCloseLogo}
            alt="icn-close"
          />
        </a>
      </div>
      <div className="uk-flex uk-overflow-auto table-data uk-text-center">
        <table className="uk-table uk-table-bordered uk-table-hover uk-table-divider">
          <thead className="uk-thead-sticky">
            <tr>
              <th className="bg-grey-50 uk-text-center">
                <Trans>TITLES.NAME</Trans>
              </th>
              <th className="bg-grey-50 uk-text-center">
                <Trans>TITLES.COMPANY</Trans>
              </th>
              <th className="bg-grey-50 uk-text-center">
                <Trans>TITLES.AFFILIATION</Trans>
              </th>
              <th className="bg-grey-50 uk-text-center w-150">
                <Trans>TITLES.TABLE_STATUS</Trans>
              </th>
              <th className="bg-grey-50 uk-text-center w-150">
                <Trans>TITLES.EVACUATION_STATUS</Trans>
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData.length > 0 ? (
              tableData.map((data, index) => {
                return (
                  <tr key={`${index}-${data.user_id}`}>
                    <td>{data.user_name || DASH}</td>
                    <td>{data?.company || DASH}</td>
                    <td>{DASH}</td>
                    <td className={getClassesForUserStatus(data.user_status)}>
                      {t(`LABELS.${data.user_status}`)}
                    </td>
                    <td>{t(`LABELS.${data.evacuation_status}`)}</td>
                  </tr>
                )
              })
            ) : (
              <tr>
                {/* --------- No Data Available --------- */}
                <td colSpan={5}>
                  <div className="uk-flex uk-flex-middle uk-flex-center min-h-335">
                    <div className="uk-empty-section uk-text-center">
                      <img
                        src={noDataAvailable}
                        height={150}
                        width={150}
                        alt=""
                      />
                      <h4 className="font-20 color-grey-blue-95 mt-30">
                        <Trans>TITLES.DATA_NOT_FOUND</Trans>
                      </h4>
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
          {/* --------- No Data Available --------- */}
        </table>
      </div>
    </div>
  )
}

export default UserSafetyListTable
