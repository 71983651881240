import { useIsMutating } from '@tanstack/react-query'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import NewSpinner from './NewSpinner'
import noDataAvailable from '../../assets/images/no-data-available.svg'

/**
 * No Data Found is common component to show  message on the page
 */
const NoDataFound = ({ msg = 'TITLES.DATA_NOT_FOUND' }: { msg?: string }) => {
  const { t } = useTranslation()
  const isMutating = useIsMutating()
  return (
    <div className="uk-flex uk-flex-middle uk-flex-center">
      {isMutating > 0 ? <NewSpinner /> : null}
      <div className="uk-empty-section uk-flex uk-flex-middle uk-flex-center uk-text-center uk-flex-column no-data-available">
        <div>
          <img src={noDataAvailable} alt="no-data-available.png" />
        </div>
        <h4 className="font-38 mt-30">
          <Trans>{msg}</Trans>
        </h4>
      </div>
    </div>
  )
}
export default NoDataFound
