import { jwtDecode } from 'jwt-decode'
import { routesConstant, urlConstant, userConstants } from '../appConstants'
import Envs from '../Envs'
import { getCookie, removeCookie } from '../utils/cookie'
import { localStorageUtils } from '../utils/localStorageUtils'
import axios from 'axios'
import type { AxiosRequestConfig } from 'axios'
import i18n from 'i18next'
const apiUrl = Envs.API_URL

let isRefreshing = false
let refreshSubscribers: any[] = []

const AxiosHttpService = async () => {
  const jwtToken = localStorageUtils.getIdToken()
  const axiosInstance = axios.create()

  const httpCredentialConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'accept-language': i18n.language,
    },
  }
  const httpJWTConfig: any = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwtToken}`,
      'accept-language': i18n.language,
      // add custom header to later check for which `Get()` method was called
      // since there are multiple methods for GET API and we need to refresh sassus for only one
      'X-Request-Function': 'Get',
    },
  }
  const htttpRefreshTokenConfig: AxiosRequestConfig = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  }

  const explicitLogout = () => {
    // clear local storage and redirect use to login page
    localStorage.removeItem('SaaSusIdToken')
    removeCookie('SaaSusRefreshToken')
    removeCookie('idToken')
    localStorage.removeItem(userConstants.userInfo)
    localStorage.removeItem(userConstants.isLoggedIn)
    localStorage.removeItem(userConstants.accessToken)
    localStorage.removeItem(userConstants.userSetting)
    localStorage.removeItem(userConstants.userPreferenceMasterData)
    localStorageUtils.removeAll()
    localStorage.clear()
    window.location.href = routesConstant.LANDING_PAGE
  }

  let retry = 0

  async function getAndSetRefreshToken() {
    // pick refresh token from cookies
    const refreshToken = getCookie('SaaSusRefreshToken')
    if (!refreshToken) {
      throw new Error('no refresh token found')
    }
    const res = await axiosInstance.get(`${apiUrl}${urlConstant.REFRESH}`, {
      ...htttpRefreshTokenConfig,
      headers: {
        ...htttpRefreshTokenConfig.headers,
        cookies: `SaaSusRefreshToken=${refreshToken}`,
      },
    })

    const idToken = res.data.id_token
    if (!idToken) {
      throw new Error('No id token found')
    }

    // set new access token for further request
    localStorage.setItem('SaaSusIdToken', idToken)

    return idToken
  }

  const onRefreshed = (token: string) => {
    refreshSubscribers.forEach((callback) => callback(token))
    refreshSubscribers = []
  }

  const subscribeTokenRefresh = (callback: any) => {
    refreshSubscribers.push(callback)
  }

  const handleError = async (error: any) => {
    const originalRequest = error.config
    // Detect CORS error by checking if response  is undefined or null
    if (!error.response) {
      return Promise.reject(error)
    }

    // check if sassus api is called
    const isCalledForSassus =
      originalRequest.headers['X-Request-Function'] == 'Get'

    if (isCalledForSassus) {
      // before refreshing, check for HTTP status code and response's status code
      if (
        error.response?.status == 401 || // update the logic once both http error code and response status is same
        error.response?.data?.status == '401'
      ) {
        try {
          retry++
          if (retry === 5) {
            // prevent continuos API calling due to tenant not found error (giving 401 when tenant not found)
            return
          }

          removeCookie('idToken')
          const idToken = await getAndSetRefreshToken()
          originalRequest.headers['Authorization'] = 'Bearer ' + idToken

          // call current API again with new token
          return axiosInstance(originalRequest)
        } catch (e) {
          explicitLogout()
          return
        }
      }

      // else redirect to no data found screen
      return Promise.reject(error)
    }
  }

  /**
   * Request/Response Interceptor
   * On response, whether status code is 401 or not. If yes fetch new DPP Access token
   * and retry original request.
   * If DPP Access token api fails to get access token, clear localstorage and
   * redirect user to login page
   */
  axiosInstance.interceptors.response.use(
    (response: any) => response,
    handleError
  )

  // Check for token expiry before calling a request
  axiosInstance.interceptors.request.use(async (config) => {
    // don't check while calling credentials API or
    // while refreshing the token itself
    if (
      config.url?.includes(urlConstant.CREDENTIALS) ||
      config.url?.includes(urlConstant.REFRESH) ||
      config.url?.includes(urlConstant.INSTALL_ANDROID_APP_LATEST)
    ) {
      return config
    }

    try {
      // check if jwt token is expired
      const token = localStorageUtils.getIdToken()
      const decoded = decodeJwtToken(jwtToken)
      const exp = decoded?.exp
      if (!exp) {
        console.warn('no expiry time found')
        return config
      }

      // Check if expired
      const expired = Date.now() >= exp * 1000
      if (!(token && expired)) {
        // if not expired, just carry on with this request
        return config
      }

      // If no token is refreshing
      if (!isRefreshing) {
        isRefreshing = true

        // remove idToken cookie - causing issues in refreshing token
        removeCookie('idToken')

        // Refresh the token if expired and set headers for current request too
        const token = await getAndSetRefreshToken()
        // after refreshing set the flag to false
        isRefreshing = false
        // And call all the APIs at once
        onRefreshed(token)

        // Return the modified config to proceed with the original request
        config.headers && (config.headers['Authorization'] = `Bearer ${token}`)
        return config
      }
    } catch (e) {
      console.error(e)
      explicitLogout()
      return Promise.reject(e)
    }

    // add request to the queue (and later resolve all of them with new token)
    return new Promise((resolve) => {
      subscribeTokenRefresh((newToken: string) => {
        config.headers &&
          (config.headers['Authorization'] = `Bearer ${newToken}`)
        resolve(config)
      })
    })
  })

  return {
    GetCredentialConfig: (url: string) =>
      axiosInstance.get(url, httpCredentialConfig),
    Get: (url: string) => axiosInstance.get(url, httpJWTConfig),
    Post: (url: string, data: any) =>
      axiosInstance.post(url, data, httpJWTConfig),
    Patch: (url: string, data: any) =>
      axiosInstance.patch(url, data, httpJWTConfig),
    Put: (url: string, data: any) =>
      axiosInstance.put(url, data, httpJWTConfig),

    Delete: (url: string) => axiosInstance.delete(url, httpJWTConfig),
    handleError: (error: any) => handleError(error),
  }
}
export default AxiosHttpService

function decodeJwtToken(
  token: ReturnType<typeof localStorageUtils.getIdToken>
) {
  if (!token) {
    throw new Error('No jwt token found!')
  }
  const decoded = jwtDecode(token)
  return decoded
}
