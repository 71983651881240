import React, { useMemo } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import type { ToastContainerProps } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

export function ToasterProvider({ children }: { children: React.ReactNode }) {
  const windowWidth = window.innerWidth

  const toastContainerStyles: ToastContainerProps['style'] = useMemo(() => {
    return {
      top: `${windowWidth > 1800 ? '200px' : '140px'}`,
    }
  }, [windowWidth])
  return (
    <>
      {children}
      <ToastContainer
        progressClassName="toast-progress active"
        hideProgressBar={true} // there is a progress bar, it is handled by custom toast component itself
        limit={1} // max one alert on screen
        pauseOnHover={false}
        style={toastContainerStyles}
      />
    </>
  )
}

import closeAlertIcon from '../../../../assets/images/icons/icn-dark-alert-close.svg'
import { alertType, toasterConfig } from '../../../../appConstants'
import type { AlertMessage } from '../../hooks/AppContextProvider/types'
import { useAppContext } from '../../hooks/AppContextProvider'

function CustomToast({ type, message }: AlertMessage) {
  const { alert } = useAppContext()
  const { setAlertMessage } = alert
  const typeClassName =
    type === alertType.SUCCESS
      ? 'success'
      : type === alertType.DANGER
      ? 'danger'
      : type === alertType.WARNING
      ? 'warning'
      : ''
  return (
    <div
      className={`alert-default uk-flex uk-flex-middle column-gap-15 uk-margin-small-top toast toast-${typeClassName} active`}
    >
      <div className={`line alert-${typeClassName}`} />
      <span>{message}</span>
      <a
        className="alert-close uk-flex uk-flex-middle uk-flex-center uk-margin-auto-left toast-close"
        onClick={() => {
          clearAllToasts()
          setAlertMessage({ type: alertType.DANGER, message: '' })
        }}
      >
        <img src={closeAlertIcon} />
      </a>
      <div className="toast-progress active" />
    </div>
  )
}

export function createToast({ message, type }: AlertMessage) {
  return toast(<CustomToast type={type} message={message} />)
}

export function clearAllToasts() {
  return toast.dismiss()
}
