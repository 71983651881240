import React from 'react'
import NoDataFound from '../../../../../components/common/NoDataFound'
import { useAuth } from './UserLogin'
import {
  publicRoutesConstant,
  roleNames,
  routesAccess,
  routesConstant,
} from '../../../../../appConstants'
import { useCurrentRoute } from 'react-navi'
import NewSpinner from '../../../../../components/common/NewSpinner'
import NavigationHeader from '../../../../../components/organisms/NavigationHeader'

const isHeaderDisplay = publicRoutesConstant.includes(window.location.pathname)

// @TODO: Remove logic once new UI integration and functionality is done
const isNewUI =
  window.location.pathname === '/application-qr-code' ||
  window.location.pathname === routesConstant.USER_LOGIN

// Check which routes can be accessed based on route path and user role
const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { role, fetchingUserInfo, tenantsList, userInfoData, } = useAuth()
  const pathname = useCurrentRoute().url.pathname
  const publicFacingURL = publicRoutesConstant.includes(pathname)

  if (fetchingUserInfo && !publicFacingURL) {
    return <NewSpinner />
  }

  // check if current page is accessible based on route and user role
  const rolesForCurrentRoute = routesAccess[pathname] ?? []

  // If it is not a public URL, And
  // if there are pre defined routes for current page and current user with current role does not have access
  const notAccessibleRoles =
    role && // check if role is coming defined (user has logged in, else redirection is handled by another component)
    role !== roleNames.app_admin && // admin has access to all the pages
    !publicFacingURL &&
    rolesForCurrentRoute &&
    !rolesForCurrentRoute.includes(role)

  const preferredTenantId = userInfoData?.user_attribute?.user_preference
  const isActiveTenantNotInTenant = !!(preferredTenantId && tenantsList && !tenantsList.find(item => item.value === preferredTenantId))

  // check access using tenants data
  //  - if nothing in tenants array, show no data screen
  //  - if user's preferred tenant not present in tenants array, show no data screen
  const notAccessibleTenants = !fetchingUserInfo && tenantsList && (
    tenantsList.length === 0 ||
    isActiveTenantNotInTenant
  )

  if (notAccessibleRoles || notAccessibleTenants) {
    return (
      <>
        {!isHeaderDisplay && !isNewUI && <NavigationHeader />}
        <NoDataFound />
      </>
    )
  }

  return (
    <>
      {children}
    </>
  )
}

export default ProtectedRoute
