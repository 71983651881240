import { userConstants } from '../appConstants'

export const localStorageUtils = {
  removeAll: () => {
    localStorage.removeItem(userConstants.userInfo)
    localStorage.removeItem(userConstants.userSetting)
    localStorage.removeItem(userConstants.userPreferenceMasterData)
    localStorage.removeItem(userConstants.isLoggedIn)
    localStorage.removeItem(userConstants.accessToken)
  },
  setUserLanguage: (language: string) => {
    localStorage.setItem(userConstants.language, language)
  },
  getUserLanguage: () => {
    const language = localStorage.getItem(userConstants.language)
    if (
      language &&
      language !== '' &&
      language !== undefined &&
      language !== 'undefined'
    ) {
      return language
    } else return 'ja'
  },
  getIdToken() {
    try {
      return localStorage.getItem(userConstants.SaaSusIdToken)
    } catch(e) {
      console.error(e);
      return null;
    }
  }
}
