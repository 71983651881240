import { urlConstant, urlParametersConstant } from '../appConstants'
import { UserPreference, UserPreferenceParam } from '../data/types/UserSetting'
import Envs from '../Envs'
import AxiosHttpService from './AxiosHttpService'
import { AxiosResponse } from 'axios'

const apiUrl = Envs.API_URL

export const AuthService = {
  fetchUserInfo: async (): Promise<any> => {
    return (await AxiosHttpService()).Get(`${apiUrl}${urlConstant.USER_INFO}`)
  },
  fetchCredentialsCode: async (code: string): Promise<any> => {
    return (await AxiosHttpService()).GetCredentialConfig(
      `${apiUrl}${urlConstant.CREDENTIALS}?${urlParametersConstant.CODE}=${code}`
    )
  },
  putUserPreference: async (
    data: UserPreferenceParam
  ): Promise<AxiosResponse<UserPreference>> => {
    return (await AxiosHttpService()).Patch(
      `${apiUrl}${urlConstant.USER_PREFERENCE}`,
      data
    )
  },
}
