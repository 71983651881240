import React from 'react'

type SpinnerProps = {
  size: number
}

const Spinner: React.FC<SpinnerProps> = ({ size }) => {
  return <span uk-spinner={`ratio: ${size}`} />
}

export default Spinner
