import React from 'react'
import Envs from '../../Envs'
import hsseLogo from '../../assets/images/hsse-logo.svg'
import jeraLogo from '../../assets/images/jera-logo.svg'

const LOGIN_URL = `${Envs.REACT_APP_LOGIN_URL}`

const LoginPage = () => {
  return (
    <div className="min-h-100vh uk-flex uk-flex-around uk-flex-center uk-flex-column">
      <div className="uk-text-center uk-flex uk-flex-middle uk-flex-column uk-margin-large-top uk-margin-large-bottom uk-mt-150">
        <a>
          <img
            src={hsseLogo}
            height={250}
            width={250}
            title="Logo"
            alt="Logo"
          />
        </a>
        <p className="font-24 line-height-2 mt-38 uk-margin-remove-bottom color-white">
          JERAの避難誘導サポートシステム
        </p>
        <p className="font-24 line-height-1 mt-24 uk-margin-remove-bottom color-white">
          ようこそ、JERA HSSEへ
        </p>
        <a
          className="uk-button uk-button-primary uk-button-large uk-btn-login mt-72"
          href={LOGIN_URL}
        >
          login
        </a>
      </div>
      <a className="footer-logo uk-flex uk-margin-auto-left uk-margin-auto-right uk-margin-small-top uk-margin-small-bottom">
        <img src={jeraLogo} width={118} height={65} title="Logo" alt="Logo" />
      </a>
    </div>
  )
}

export default LoginPage
