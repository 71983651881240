import { useCurrentRoute } from 'react-navi'
import { localStorageUtils } from '../../utils/localStorageUtils'
import { routesConstant } from '../../appConstants'

export function useRedirect() {
  const idToken = localStorageUtils.getIdToken()
  const pathname = useCurrentRoute()?.url.pathname
  const isLoginPage = pathname?.includes(routesConstant.USER_LOGIN)
  const isQrPage = pathname?.includes(routesConstant.APPLICATION_QR_CODE)
  const isDashboardPage = pathname === '/'
  const isTokenPresent = !!idToken

  if (isDashboardPage && !isTokenPresent) {
    window.location.href = routesConstant.USER_LOGIN
  }

  if (isLoginPage && isTokenPresent) {
    window.location.href = routesConstant.LANDING_PAGE
  }


  if (isQrPage && !isTokenPresent) {
    window.location.href = routesConstant.LANDING_PAGE
  }
}
