import React from 'react'
import hsseAppLogo from '../../assets/images/hsse-logo.svg'
import footerLogo from '../../assets/images/footer-logo.svg'

const TermsAndConditions = () => {
  return (
    <div className="">
      <div className="terms-parent min-h-100vh uk-flex uk-flex-column">
        <header className="header uk-flex uk-flex-middle uk-flex-between">
          <a>
            <img src={hsseAppLogo} title="Logo" alt="Logo" />
          </a>
        </header>

        <div className="terms-main uk-flex uk-flex-1 uk-flex-column uk-flex-between">
          <p className='uk-margin-remove-bottom'>このご利用規約（以下「本規約」といいます。）は、株式会社JERAが所有し、管理するアプリケーション及び関連ソフトウェア（以下併せて「本アプリ」といいます。）の利用者（以下「利用者」といいます。）へのご提供と利用者のご利用の条件について定めるものです。利用者が本アプリを利用するにあたっては、本規約の承諾と遵守をお約束頂きます。また、本アプリを利用する場合、本規約に同意したものと見なされます。従って、本規約に同意した上で本アプリを利用してください。本規約の条項のどれかであってもご同意いただけない場合、本アプリを利用することはできませんので、その場合は直ちに本アプリの利用を中止してください。ななお、本規約に基づき、利用者の本アプリの利用に関して当社と利用者との間に成立する合意を「本契約」といいます。</p>

          <h2 className="uk-text-capitalize uk-text-bold">
            記
          </h2>

          <div className="">
            <h3 className="uk-text-capitalize uk-text-bold uk-margin-small-bottom mt-20">
              1. 規約の変更
            </h3>
            {/* <span>Last Updated :- {dateString}</span> */}
            <p>当社は、当社が必要と判断する場合、あらかじめお客様に通知することなく、いつでも、本規約および個別利用規約を変更できるものとします。変更後の本規約および個別利用規約は、当社が運営するウェブサイト内の適宜の場所に掲示された時点からその効力を生じるものとし、お客様は本規約および個別利用規約の変更後も本サービスを使い続けることにより、変更後の本規約および適用のある個別利用規約に対する有効かつ取消不能な同意をしたものとみなされます。かかる変更の内容をお客様に個別に通知することはいたしかねますので、本サービスをご利用の際には、随時、最新の本規約および適用のある個別利用規約をご確認ください。</p>
          </div>

          <div className="">
            <h3 className="uk-text-capitalize uk-text-bold uk-margin-small-bottom mt-20">
              2. アカウント
            </h3>
            <p>
            （１）本アプリは、発電所の入構に際してご利用頂くことができます。また、お客様は、本サービスの利用に際してお客様ご自身に関する情報を登録する場合、真実、正確かつ完全な情報を提供しなければならず、常に最新の情報となるよう修正しなければなりません。
            </p>
            <p>
            （2）お客様は、本サービスの利用に際してパスワードを登録した時点より、不正に利用されないようお客様ご自身の責任で厳重に管理しなければなりません。当社は、登録されたパスワードを利用して行なわれた一切の行為を、お客様ご本人の行為とみなします。
            </p>
            <p>
            （3）当社は、お客様が本規約に違反しまたは違反するおそれがあると認めた場合、あらかじめお客様に通知することなく、アカウントを停止または削除することができます。
            </p>
            <p>
            （4） お客様の本サービスにおけるすべての利用権は、理由を問わず、アカウントが削除された時点で消滅します。
            </p>
            <p>
              （5） 本サービスのアカウントは、お客様に一身専属的に帰属します。お客様の本サービスにおけるすべての利用権は、第三者に譲渡、貸与または相続させることはできません。
            </p>
          </div>

          <div className="">
            <h3 className="uk-text-capitalize uk-text-bold uk-margin-small-bottom mt-20">
              3.  サービスの提供
            </h3>
            <p>（1） お客様は、本サービスを利用するにあたり、必要な端末装置（スマートフォン）及び通信回線、その他必要な環境は全てお客様の費用と責任で準備する事とする</p>
            <p>（2） 当社は、当社が必要と判断する場合、あらかじめお客様に通知することなく、いつでも、本サービスの全部または一部の内容を変更し、また、その提供を中止することができるものとします。</p>
            <p>（3） 本サービスの避難情報の提供について、利用状況下が災害時である場合も多い。その為、情報の信頼性、本システムの可動の有無に関して当社は一切責任を負わない。これにご理解いただけない場合は、直ちに本アプリの利用を中止してください。</p>
          </div>

          <div className="">
            <h3 className="uk-text-capitalize uk-text-bold uk-margin-small-bottom mt-20">
              4. 個人情報
            </h3>
            <p>（1） 当社は、お客様のプライバシーを尊重しています。</p>
            <p>（2） 本サービスは、個人情報をサーバーに基本、個人情報を蓄積しません。</p>
            <p>（3） 当社は、お客様から収集した情報を安全に管理するため、セキュリティに最大限の注意を払っています。</p>
          </div>

          <div className="">
            <h3 className="uk-text-capitalize uk-text-bold uk-margin-small-bottom mt-20">
              5. 禁止事項
            </h3>
            <p>お客様は本サービスの利用に関して、次の各号に定める行為を行ってはならないものとする。</p>
            <p>（1） 著作権等知的財産権及びその他権利を侵害し、または侵害するおそれのある行為。</p>
            <p>（2） 公序良俗及び諸法令に違反した活用</p>
            <p>（3） 第三者に本件サービスを利用させる行為及び本契約にて許諾された権利を第三者に譲渡、
            貸与、共有等する行為。</p>
            <p>（4） 本件サービスの内容および本件サービスにより利用しうる情報を改竄、消去する行為。</p>
            <p>（5） 第三者になりすまして本件サービスを利用し、その他不正アクセス行為に該当する行為。</p>
            <p>（6） コンピュータウィルス及びその他有害なコンピュータプログラム等を送信する行為。</p>
            <p>（7） 本件サービス用設備（サーバー等）に支障を与える行為、または与えるおそれのある行為。</p>
            <p>（8） 法令に違反し、または違反する疑いのある行為。</p>
            <p>（9） その他、上記各号に相当する程度に不適切であると乙が判断する行為。</p>
          </div>

          <div className="">
            <h3 className="uk-text-capitalize uk-text-bold uk-margin-small-bottom mt-20">
              6. お客様の責任
            </h3>
            <p>（1） お客様は、お客様ご自身の責任において本サービスを利用するものとし、本サービスにおいて行った一切の行為およびその結果について一切の責任を負うものとします。</p>
            <p>（2） 当社は、お客様が本規約に違反して本サービスを利用していると認めた場合、当社が必要かつ適切と判断する措置を講じます。ただし、当社は、かかる違反を防止または是正する義務を負いません。</p>
            <p>（3） お客様は、本サービスを利用したことに起因して（当社がかかる利用を原因とするクレームを第三者より受けた場合を含みます。）、当社が直接的もしくは間接的に何らかの損害（弁護士費用の負担を含みます。）を被った場合、当社の請求にしたがって直ちにこれを補償しなければなりません。</p>
          </div>

          <div className="">
            <h3 className="uk-text-capitalize uk-text-bold uk-margin-small-bottom mt-20">
              7. 当社の免責
            </h3>
            {/* <span>Last Updated :- {dateString}</span> */}
            <p>（1） 当社は、本サービス（本コンテンツを含みます。）に事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。当社は、お客様に対して、かかる瑕疵を除去して本サービスを提供する義務を負いません。</p>
            <p>（2） 当社は、本サービスに起因してお客様に生じたあらゆる損害について一切の責任を負いません。ただし、本サービスに関する当社とお客様との間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合、この免責規定は適用されません。</p>
            <p>（3） 上記（2）ただし書に定める場合であっても、当社は、当社の過失（重過失を除きます。）による債務不履行または不法行為によりお客様に生じた損害のうち特別な事情から生じた損害（当社またはお客様が損害発生につき予見し、または予見し得た場合を含みます。）について一切の責任を負いません。また、当社の過失（重過失を除きます。）による債務不履行または不法行為によりお客様に生じた損害の賠償は、お客様から当該損害が発生した月に受領した利用料の額を上限とします。</p>
          </div>

          <div className="">
            <h3 className="uk-text-capitalize uk-text-bold uk-margin-small-bottom mt-20">
              8. 裁判管轄
            </h3>
            <p>本規約は日本語を正文とし、その準拠法は日本法とします。本サービスに起因または関連してお客様と当社との間に生じた紛争については東京地方裁判所を第一審の専属的合意管轄裁判所とします。</p>
          </div>

          <a className="footer-logo uk-flex uk-margin-auto-left uk-margin-auto-right uk-margin-small-top uk-margin-small-bottom">
            <img
              src={footerLogo}
              width="100"
              height=""
              title="Logo"
              alt="Logo"
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default TermsAndConditions
