import { userStatus } from '../appConstants'
import icnRedUser from '../assets/images/icn-red-user.svg'
import icnGreenUser from '../assets/images/icn-user-green-70.svg'
import icnGreyUser from '../assets/images/icn-user-grey.svg'
import icnOrangeUser from '../assets/images/icn-user-orange.svg'

const { SERIOUS_INJURY, MINOR_INJURY, SAFE, NOT_ANSWERED } = userStatus

export const getClassesForUserStatus = (
  status: keyof typeof userStatus | string
) => {
  switch (status) {
    case SERIOUS_INJURY:
      return 'color-red'
    case MINOR_INJURY:
      return 'color-orange'
    case SAFE:
      return 'color-green'
    case NOT_ANSWERED:
      return ''
    default:
      return ''
  }
}

export const getUserStatusIcon = (status: keyof typeof userStatus) => {
  switch (status) {
    case SERIOUS_INJURY:
      return icnRedUser
    case MINOR_INJURY:
      return icnOrangeUser
    case SAFE:
      return icnGreenUser
    case NOT_ANSWERED:
      return icnGreyUser
    default:
      return icnGreyUser
  }
}

export const statusColors = (status: keyof typeof userStatus) => {
  switch (status) {
    case SAFE:
      return '#70b42c'
    case MINOR_INJURY:
      return '#f0aa54'
    case SERIOUS_INJURY:
      return '#d82531'
    case NOT_ANSWERED:
      return '#6c696a'
    default:
      return '#6c696a'
  }
}

export const convertNumberToContainMinimunThreeDigits = (
  count: number | undefined
) => {
  if (!count) return ''
  return count < 10 ? `00${count}` : count < 100 ? `0${count}` : `${count}`
}
