import React from 'react'
import { createPortal } from 'react-dom'
import { Trans } from 'react-i18next'
import cn from 'classnames'

/**
 * Confirmation box is common component to show confirm modal display
 * @param yesAction yesAction is function  for yes button item
 * @param noAction noAction is function  for no button click
 * @param messageTitle messageTitle text as translated string to show on modal title
 * @param messageBody messageBody text as translated string to show on modal body description
 * @returns ConfirmBoxModal component
 */

type ConfirmBoxModalCustomCss = {
  yesButtonBackGround?: string
  noButtonBackGround?: string
  yesButtonIcon?: string
  noBUttonIcon?: string
}

const ConfirmBoxModal: React.FC<{
  yesText?: string
  noText?: string
  yesAction: () => void
  noAction: () => void
  messageTitle?: string | any
  messageBody: string | any
  customCss?: ConfirmBoxModalCustomCss
  variant?: 'small' | 'large'
}> = ({
  noAction,
  yesAction,
  messageTitle,
  messageBody,
  yesText,
  noText,
  customCss,
  variant = 'small',
}) => {
  return createPortal(
    <div className="modal-area without-overlay-bg">
      <div className="modal-dialog uk-text-center color-white bg-grey-95">
        <div className="warning-area uk-flex uk-flex-middle column-gap-22">
          <div className="square-repetition" />
          <div className="warning-heading color-red line-height-1">WARNING</div>
          <div className="square-repetition" />
        </div>
        <div
          className={cn(
            variant === 'large' && 'modal-body uk-padding-medium-left'
          )}
        >
          {messageTitle ? (
            <h2
              className={cn(
                variant === 'large'
                  ? 'modal-title uk-margin-small-top uk-margin-remove-bottom'
                  : 'modal-title-3 uk-margin-medium-top uk-margin-medium-bottom modal-h2-title',
                !messageBody && 'modal-h2-title-lg'
              )}
            >
              {messageTitle}
            </h2>
          ) : null}
          {messageBody ? (
            <p
              dangerouslySetInnerHTML={{ __html: messageBody }}
              className={cn(
                variant === 'large'
                  ? 'modal-description uk-margin-remove-top mb-20'
                  : 'modal-p-text',
                !messageTitle && 'uk-margin-medium-top'
              )}
            ></p>
          ) : null}
          <div
            className={cn(
              variant === 'large'
                ? 'modal-footer mb-30 uk-flex uk-flex-middle uk-flex-around'
                : 'modal-footer uk-margin-medium-bottom uk-flex uk-flex-middle uk-flex-around'
            )}
          >
            <button
              data-id="yesButton"
              type="button"
              className="uk-button uk-button-link color-white"
              onClick={yesAction}
            >
              {yesText || <Trans>BUTTONS.CONFIRM_YES</Trans>}
            </button>
            <button
              data-id="noButton"
              className="uk-button uk-button-link uk-modal-close color-white"
              type="button"
              onClick={noAction}
            >
              {noText || <Trans>BUTTONS.CONFIRM_NO</Trans>}
            </button>
          </div>
        </div>
        <div className="warning-area">
          <div className="square-repetition" />
        </div>
      </div>
    </div>,
    document.body
  )
}

export default ConfirmBoxModal
