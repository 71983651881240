import { AxiosResponse } from 'axios'
import { urlConstant, urlParametersConstant } from '../appConstants'
import AxiosHttpService from './AxiosHttpService'
import Envs from '../Envs'
import { ApkData } from '../data/types/AppQRCode'

const apiUrl = Envs.API_URL

const { QR_CODE, INSTALL_ANDROID_APP_LATEST } = urlConstant

const { TENANTS } = urlParametersConstant

export const QRCodeAndApkService = {
  fetchQRCodeData: async (
    tenantId: string,
    qrCodeType: string
  ): Promise<AxiosResponse<any>> => {
    return (await AxiosHttpService()).Get(
      `${apiUrl}${TENANTS}/${tenantId}/${QR_CODE}/${qrCodeType}`
    )
  },
  fetchDownloadApkData: async (): Promise<AxiosResponse<ApkData>> => {
    return (await AxiosHttpService()).Get(
      `${apiUrl}${INSTALL_ANDROID_APP_LATEST}`
    )
  },
}
