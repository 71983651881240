import React from 'react'
import HSSELogo from '../../assets/images/hsse-apk.svg'
import FileDownloadLogo from '../../assets/images/file-apk.svg'
import IconDownloadLogo from '../../assets/images/icn-download-apk.svg'
import FooterLogo from '../../assets/images/footer-logo-apk.svg'
import { useIsMutating, useQuery } from '@tanstack/react-query'
import { QRCodeAndApkService } from '../../services/QRCodeAndApkService'
import NewSpinner from '../../components/common/NewSpinner'

const ApkDownload = () => {
  const { data: apkLatestData, isLoading: apkLatestIsLoading } = useQuery({
    queryKey: ['apk-latest'],
    // enabled: !!currentTenantId,
    queryFn: () => QRCodeAndApkService.fetchDownloadApkData(),
  })

  const apkDownloadUrl = apkLatestData?.data?.url || ''
  const fileVersion = apkLatestData?.data?.version || ''
  const fileName = `HSSE_Demo_${fileVersion}.apk`

  return (
    <div className="uk-light bg-white">
      {apkLatestIsLoading && <NewSpinner />}
      <div className="apk-parent">
        <header className="apk-header uk-flex uk-flex-middle uk-flex-between">
          <a>
            <img src={HSSELogo} title="Logo" alt="Logo" />
          </a>
        </header>
        <div className="uk-flex uk-flex-between uk-flex-center uk-flex-column uk-flex-1">
          <div className="apk-body uk-text-center uk-margin-large-top uk-margin-large-bottom">
            <div className="apk-demo">
              <img src={FileDownloadLogo} />
              <p className="color-grey-65 font-20 apk-content">
                {apkLatestIsLoading ? null : fileName}
              </p>
            </div>
            <a
              id="apk-donwload-link"
              href={apkDownloadUrl}
              download={fileName}
              className="download-apk"
            >
              <img src={IconDownloadLogo} title="Logo" />
            </a>
          </div>
          <a className="footer-logo uk-flex uk-margin-auto-left uk-margin-auto-right uk-margin-small-top uk-margin-small-bottom">
            <img
              src={FooterLogo}
              width="126"
              height="72"
              title="Logo"
              alt="Logo"
              className="apk-logo"
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default ApkDownload
