export const TRANSLATIONS_EN = {
  PRODUCT_NAME: 'Disaster CounterMeasures',
  TITLES: {
    REGISTRATION: 'Sign Up',
    USER_ID: 'User ID',
    NAME: 'Name',
    SELECT: 'Select…',
    ALL: 'All',
    DATA_NOT_FOUND: 'Data does not exist',
    USER_DATA_NOT_FOUND: 'There is no user safety registration record.',
    DISASTER_INFORMATION_MANAGEMENT: 'Disaster information management',
    DISASTER_CONFIRMATION_TITLES: 'Change of disaster occurrence status',
    DISASTER_CONFIRMATION_CONTENT:
      'A disaster will be recognized, location information of people inside the power plant will be obtained, and emails will be sent to confirm the safety of the people inside the power station. is this good?',
    DISASTER_CONFIRMATION_COMPLETE:
      'Please register the location of the disaster',
    DISASTER_CANCELLATION_TITLES: 'Change of disaster occurrence status',
    DISASTER_CANCELLATION_CONTENT:
      'Disaster certification will be canceled. You will not be able to display location information or register your safety status. is this good?',
    DISASTER_CANCELLATION_COMPLETE:
      'The disaster registration has been cancelled',
    DISASTER_LOCATION_CONFIRMATION_TITLES: '', // Registration of disaster location
    DISASTER_LOCATION_CONFIRMATION_CONTENT:
      'Designate the selected location as a disaster location. is this good?',
    LOCATION_INFORMATION: 'Location Information',
    DISASTER_LOCATION_UPDATE_CONFIRMATION_TITLE: '',
    DISASTER_PRACTICE_LOCATION_UPDATE_CONFIRMATION_TITLE: '',
    DISASTER_LOCATION_UPDATE_CONFIRMATION:
      'Do you want to change the disaster location and resume evacuation guidance support?',
    USER_NAME: 'User Name',
    AFFILIATION: 'Affiliation',
    STATUS: 'Status',
    EVACUATION_STATUS: 'Evacuation Status',
    SAFETY_REGISTRATION_STATUS: 'Safety Registration Status',
    NO_SEARCH_DATA_FOUND: 'Oops! No data found.',
    SEARCH_TRY_AGAIN: 'Please check your search criteria and try again.',
    NORMAL_MODE: 'Normal mode',
    DISASTER_MODE: 'Disaster mode',
    DISASTER_LOCATION: 'Disaster location setting',
    EVACUATION_GUIDANCE_SUPPORT: 'Evacuation guidance support in progress',
    DOUBLE_CLICK_TO_REGISTER:
      'Please double-click on the disaster location to set it up',
    DISASTER_END: 'Thank you for your efforts in responding to the disaster.',
    SHOW_HIDE_FACILITY_ICONS: 'Show / Hide facility icons',
    REGISTER_ITEMS_SETTINGS: 'Facility Icon Registration Settings',
    COMPANY: 'Company',
    NOT_FOUND: 'Oops! No data found.',
    TURN_ON_TOGGLE: 'To register an icon, turn on the facility icon.',
  },
  LABELS: {
    POWER_PLANT: 'Power Plant',
    UNIT: 'Unit',
    LANGUAGE: 'Language',
    JAPANESE: 'Japanese',
    ENGLISH: 'English',
    ERROR: 'Error',
    LANGUAGE_SETTING: 'Language Setting',
    FEEDBACK: 'Feedback',
    SERIOUS_INJURY: 'Serious injury',
    MINOR_INJURY: 'Minor injury',
    SAFE: 'Safety',
    NOT_ANSWERED: 'Not answered',
    SELECT_ALL: 'All',
    OTHER: 'Other',
    PEOPLE: 'People',
    EVACUATION_COMPLETED: 'Evacuation completed',
    TOTAL_NUMBER: 'Total number of ',
    COMPANY_SELECTION: 'Company Selection',
    EVACUATION_STATUS_SELECTION: 'Evacuation Status Selection',
    VISITORS: 'Visitors',
    LIST: 'List',
    EQUIPMENT_ICON: 'Equipment Icon',
    QR_PAGE_MENU_ITEM: 'QR Code Issuance for Visitors',
    TRAINS: 'Trains',
    TABLE_STATUS: 'Status',
    CHANGE_KEYWORD: 'Change search keyword or add new from this keyword',
    POWER_PLANT_USER: 'Power Plant User',
    GUEST_USER: 'guest user',
  },
  BUTTONS: {
    CANCEL: 'Cancel',
    REGISTER: 'Register',
    LOGOUT: 'LogOut',
    FEEDBACK: 'Feedback',
    SUBMIT: 'Submit',
    CONFIRM_NO: 'No',
    CONFIRM_YES: 'Yes',
    DISASTER: 'Disaster',
    CANCELLATION: 'Cancellation',
    CLEAR: 'Clear',
    APPLY: 'Apply',
  },
  ERRORS: {
    INVALID_USERSETTING:
      'Invalid user settings were found, please contact administrate.',
    MR_IR_004:
      'Unexpected error occurred. Please contact to system administrator.',
    MR_IR_005: 'Session expired. Please login again.',
    MR_IR_006: 'Cannot get data.',
    MR_IR_007: 'Cannot save data. Other users has already changed data.',
    MA_IR_011: 'Cannot save user preferences. Please try again after logout.',
    MA_IR_012:
      'Updating user preferences failed. Please contact to system administrator.',
    MR_IR_013: 'Please register User information.',
    MA_IR_016: 'Failed to link with disaster management (DCM).',
  },
}
