// index.tsx
import React from 'react'
import 'regenerator-runtime/runtime'

// Need to replace these folder/files contents to older folder/files when full UI integration and functionality are done
import './assets/css2/uikit.min.css'
import './assets/sass2/main.scss'
import 'maplibre-gl/dist/maplibre-gl.css'
import './translations/i18n'
import { App } from './App'
import * as Uikit from 'uikit';

import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<App />);
