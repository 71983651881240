/**
 * HTTP ステータスコード別のエラーメッセージを返す
 */
export const checkResponseStatus = (status: number): string => {
  switch (status) {
    case 200:
    case 204:
      return ''
    case 201:
      return ''
    case 400:
      return '想定しないエラーが発生しました。システム管理者に問い合わせてください。'
    case 401:
      return 'セッションの有効期限が切れました。再度ログインしてください。'
    case 404:
      return 'データの取得に失敗しました。'
    case 409:
      return '他のユーザーが既に更新しています。入力内容を保存することができませんでした。'
    default:
      return '想定しないエラーが発生しました。システム管理者に問い合わせてください。'
  }
}
