import { checkResponseStatus } from '../context/login/utils/HttpRequestUtils'
import { catchHttpError } from './getHttpCatchError'

export const getResponseMessage = (res: any): string | null => {
  const statusCode = res?.status
  const messageStatus =
    res?.data?.message &&
    res?.data?.message !== '' &&
    res?.data?.message !== null
      ? res?.data?.message
      : checkResponseStatus(res.status)
  if (statusCode === 200 || statusCode === 201 || statusCode === 204) {
    return null
  } else {
    return messageStatus
  }
}

export const getErrorMessage = (err: any): string => {
  const errorMessageStatus =
    err?.response?.data?.message &&
    err?.response?.data?.message !== '' &&
    err?.response?.data?.message !== null
      ? err?.response?.data?.message
      : catchHttpError.catchErrorMessage(err)
  return errorMessageStatus
}
