import { getHttpError } from "./getHttpError";
export const catchHttpError = {
  catchErrorMessage: (err: any) => {
    let errorMessage = "";
    /* checking error exist or not*/
    const httpError = getHttpError(err,0);
    if (httpError) {
      errorMessage=httpError;
    } 
    return errorMessage;
  },
  totCatchErrorMessage: (err: any) => {
    let errorMessage = "";
    /* checking error exist or not*/
    const httpError = getHttpError(err,1);
    if (httpError) {
      errorMessage=httpError;
    } 
    return errorMessage;
  }
};

