import React from 'react'

import IoSImage from '../../assets/images/download-qr-ios.svg'
import AndroidImage from '../../assets/images/download-qr-android.svg'
import { useAuth } from '../../context/login/components/organisms/Protected/UserLogin'
import { useIsMutating, useQuery } from '@tanstack/react-query'
import { qrCodeType } from '../../appConstants'
import NewSpinner from '../../components/common/NewSpinner'
import NavigationHeader from '../../components/organisms/NavigationHeader'
import { QRCodeAndApkService } from '../../services/QRCodeAndApkService'

const ApplicationQrCode = () => {
  const { currentTenantId } = useAuth()
  const { data: qrCodeIos, isLoading: iosDataFetching } = useQuery({
    queryKey: ['qr-code-ios', currentTenantId],
    enabled: !!currentTenantId,
    queryFn: () =>
      QRCodeAndApkService.fetchQRCodeData(
        currentTenantId,
        qrCodeType.INSTALL_IOS_APP
      ),
  })
  const { data: qrCodeAndroid, isLoading: androidDataFetching } = useQuery({
    queryKey: ['qr-code-android', currentTenantId],
    enabled: !!currentTenantId,
    queryFn: () =>
      QRCodeAndApkService.fetchQRCodeData(
        currentTenantId,
        qrCodeType.INSTALL_ANDROID_APP
      ),
  })
  const {
    data: qrCodeCreateAccount,
    isLoading: createAccountDataFetching,
  } = useQuery({
    queryKey: ['qr-code-create-account', currentTenantId],
    enabled: !!currentTenantId,
    queryFn: () =>
      QRCodeAndApkService.fetchQRCodeData(
        currentTenantId,
        qrCodeType.CREATE_ACCOUNT
      ),
  })

  const iosQRCodeImageUrl = qrCodeIos?.data?.url
  const androidQRCodeImageUrl = qrCodeAndroid?.data?.url
  const createAccountQRCodeImageUrl = qrCodeCreateAccount?.data?.url

  const isFetching =
    iosDataFetching || androidDataFetching || createAccountDataFetching

  const isMutating = useIsMutating()
  return (
    <>
      {isFetching || isMutating > 0 ? <NewSpinner /> : null}
      <NavigationHeader />
      <div className="uk-flex qr-screen">
        <div className="qr-part-one uk-flex-1 uk-flex uk-flex-column">
          <div className="color-white heading-part">
            <span className="uk-text-bold font-120 line-height-1">
              DOWNLOAD
            </span>
            <p className="font-38 uk-margin-remove">アプリダウンロード用</p>
          </div>
          <div className="uk-flex uk-flex-middle uk-flex-between qr-area-parent">
            <div className="qr-area-first uk-flex uk-flex-middle uk-flex-column uk-flex-between">
              <div className="qr-area-box">
                {/* QR Code Image [IOS] */}
                <img src={iosQRCodeImageUrl} alt="QR" />
              </div>
              <a href="#">
                <img src={IoSImage} width="100%" alt="QR" />
              </a>
            </div>
            <div className="qr-area-first uk-flex uk-flex-middle uk-flex-column uk-flex-between">
              <div className="qr-area-box">
                {/* QR Code Image [Android] */}
                <img src={androidQRCodeImageUrl} alt="QR" />
              </div>
              <a href="#">
                <img src={AndroidImage} width="100%" alt="QR" />
              </a>
            </div>
          </div>
        </div>
        <div className="uk-flex uk-flex-column uk-flex-right">
          <div className="qr-part-two uk-flex uk-flex-middle uk-flex-column uk-flex-center">
            <div className="color-black uk-text-center heading-part">
              <span className="uk-text-bold line-height-1">SIGN UP</span>
              <p className="uk-margin-remove">アカウント発行用</p>
            </div>
            <div className="qr-area-box">
              {/* QR Code Image [Create Account] */}
              <img src={createAccountQRCodeImageUrl} width="100%" alt="QR" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ApplicationQrCode
